<template>
  <div
    :class="{'card-no-move': isTrainingType}"
    class="card"
  >
    <svg-icon
      v-if="!isTrainingType"
      icon-class="sort"
      class="card__sort-icon"
    />
    <div class="input-field">
      <label class="input-field__label">{{ $t('model.upload.columnDataType') }}</label>
      <div
        v-if="!isTrainingType"
        class="input-field__input"
      >
        <template v-if="modelType === 'UPLOADED_SINGLE_RECORD'">
          <default-select
            v-validate="'required'"
            :option-list="dataTypeOptionList"
            :placeholder="$t('batchLoad.chooseColumn')"
            :is-disabled="isProcessing || isLoading"
            v-model="columnInfo.statsType"
            :name="'select' + columnInfo.id"
            filterable
            class="input-field__select"
            @change="$emit('updateDataColumn', $event)"
          />
          <div
            v-show="errors.has('select' + columnInfo.id)"
            class="error-text"
          >
            {{ errors.first('select' + columnInfo.id) }}
          </div>
        </template>
        <div
          v-else
          class="data-frame-field"
        >
          DataFrame
        </div>
      </div>
      <div
        v-else
        class="states-type-field"
      >
        {{ columnInfo.statsType }}
      </div>
    </div>
    <div class="input-field">
      <label class="input-field__label">{{ $t('model.upload.columnDataName') }}</label>
      <input-verify
        v-validate="`required|letterDashUnderscore|max:${max}`"
        v-model.trim="columnInfo[inputType]"
        :placeholder="$t('editing.pleaseEnterName')"
        :name="'input' + columnInfo.id"
        type="text"
      />
    </div>
    <div
      v-if="columnList.length > 1 && !isTrainingType"
      :class="{ 'disabled': isProcessing || isLoading }"
      class="card__delete-icon"
      @click="removeColumn"
    >
      <svg-icon
        icon-class="delete"
        class="icon"
      />
    </div>
  </div>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect'
import InputVerify from '@/components/InputVerify'

export default {
  name: 'ModelColumnSettingCard',
  inject: ['$validator'],
  components: {
    DefaultSelect,
    InputVerify
  },
  props: {
    columnList: {
      type: Array,
      default: () => []
    },
    dataTypeOptionList: {
      type: Array,
      default: () => []
    },
    columnInfo: {
      type: Object,
      default: () => ({})
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    modelType: {
      type: String,
      required: true
    }
  },
  computed: {
    max () {
      return this.$store.getters['validation/fieldCommonMaxLength']
    },
    inputType () {
      return this.modelType !== 'UPLOADED_MULTI_DATASET' ? 'modelColumnName' : 'modelDataFrameName'
    },
    isTrainingType () {
      return this.modelType === 'TRAINED'
    }
  },
  methods: {
    removeColumn () {
      if (this.isProcessing || this.isLoading) return
      this.$emit('remove', this.columnInfo.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 18px;
  border-radius: 8px;
  background-color: rgba(72, 84, 84, .95);
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: move;

  &__sort-icon {
    margin-right: 24px;
    font-size: 32px;
    color: #8F9595;
  }

  &__delete-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    &:hover {
      color: $theme-color-primary;
    }

    &.disabled {
      opacity: .7;
      cursor: not-allowed;
    }
  }

  .input-field {
    &:not(:last-of-type) {
      margin-right: 24px;
    }

    .error-text {
      position: absolute;
    }
  }

  .data-frame-field {
    padding-top: 12px;
  }

  .states-type-field {
    padding-top: 16px;
  }

  ::v-deep .input-verify .input-verify-text {
    margin-bottom: 0;
  }

  ::v-deep .input-verify .input-error {
    position: absolute;
    bottom: 0;
    top: 100%;
  }

  ::v-deep .el-input__inner {
    padding-left: 0;
    border-bottom: 1px solid #FFFFFF;
  }

  ::v-deep .input-field {
    &__label {
      font-size: 14px;
      color: #CCCCCC;
    }

    .el-input__inner {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.card-no-move {
  cursor: unset;
}
</style>
